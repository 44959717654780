import {
  CategoryTitle,
  Container,
  FlexContainer,
  HorizontalCarouselAPI,
  MediaCard,
  MediaCardSkeleton
} from '@cheddartv/storybook-news'

import React, { useCallback, useMemo, useRef, useState } from 'react'

import { trackGTMEvent } from '../../config/analytics'
import internalApiQueryWrapper from '../../core/internalApiQueryWrapper'
import { StoryListByCategory } from '../../data/query/homepage/fetchHomepageData'
import useBelowBreakpoint from '../../hooks/useBelowBreakpoint'
import { getDuration } from '../../utils/duration'
import AdUnit from '../Ads/AdUnit'
import InfiniteHorizontalCarousel from '../HorizontalCarousel/InfiniteHorizontalCarousel'
import useSlidesToScroll from '../HorizontalCarousel/useSlidesToScroll'

type StoryCollectionCarouselProps = {
  collection: StoryListByCategory
  withAds?: boolean
}

const StoryCollectionCarousel = ({ collection, withAds }: StoryCollectionCarouselProps) => {
  const slidesToScroll = useSlidesToScroll()

  const { category, slug, queryParams } = collection

  const [storiesList, setStoriesList] = useState(collection.stories)
  const [isLoading, setIsLoading] = useState(false)

  const storiesLengthRef = useRef(storiesList.length)

  const formattedCategoryName = useMemo(() => category?.replace(/ /g, '_').toLowerCase() ?? 'no_category', [category])

  const isMobile = useBelowBreakpoint('sm')

  const handleLoadMore = useCallback(async () => {
    setIsLoading(true)

    const { stories: moreStories } = await internalApiQueryWrapper('/api/contentful/moreStories', {
      ...queryParams,
      skip: storiesLengthRef.current.toString()
    })

    setStoriesList(prevStoriesList => {
      const updatedStoriesList = prevStoriesList.concat(moreStories)
      storiesLengthRef.current = updatedStoriesList.length

      return prevStoriesList.concat(moreStories)
    })
    setIsLoading(false)
  }, [queryParams, storiesLengthRef])

  const handleCarouseInit = useCallback(
    (carouselApi: HorizontalCarouselAPI) => {
      carouselApi.on('select', () => {
        trackGTMEvent.gaCarouselScroll({ category })
      })
    },
    [category]
  )

  return (
    <Container
      className='story-collection'
      margin={['1.5rem auto', '2.5rem auto']}
      padding={{ default: '0 0 0 1rem', sm: '0 3.75rem' }}>
      <FlexContainer direction='column' gap='1.5rem'>
        <CategoryTitle uppercased withArrow withMarker fontWeight='semibold' href={`category/${slug}`}>
          {category}
        </CategoryTitle>

        <Container margin={{ default: '0 0 0 -1rem', sm: ' 0' }} padding='0'>
          <InfiniteHorizontalCarousel
            options={{ slidesToScroll }}
            onInit={handleCarouseInit}
            onLoadMore={handleLoadMore}>
            {storiesList.map(({ id, title, thumbnail, videoFile, slug }, storiesIndex) => (
              <>
                {withAds && storiesIndex === 0 && !isMobile && (
                  <AdUnit
                    adUnitTemplate='square'
                    id={`ad-story-${formattedCategoryName}-${storiesIndex}`}
                    type='storiesCollection'
                  />
                )}
                <MediaCard
                  inverseTextColor
                  durationTime={getDuration(videoFile?.duration)}
                  key={id}
                  heading={title}
                  onClick={() =>
                    trackGTMEvent.gaMediaCardClick({
                      cardTitle: title,
                      sectionTitle: category,
                      cardNumber: storiesIndex + 1
                    })
                  }
                  thumbnail={{
                    alt: title,
                    src: thumbnail
                  }}
                  href={slug}
                />
              </>
            ))}
            {isLoading && (
              <>
                <MediaCardSkeleton />
                <MediaCardSkeleton />
                <MediaCardSkeleton />
              </>
            )}
          </InfiniteHorizontalCarousel>
        </Container>
      </FlexContainer>
    </Container>
  )
}

export default StoryCollectionCarousel
